import { defineStore } from "pinia";

export const useHomeStore = defineStore("home", {
  state: () => {
    return {
      displayName: "",
      pageSize: 100,
      toast: {
        status: false,
        type: "",
        message: "",
      },
    };
  },
  actions: {
    closeAlert() {
      this.toast = {
        status: false,
        type: "",
        message: "",
      };
    },
  },
});
