import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/MainLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "authLayout",
    component: AuthLayout,
    meta: { requiresAuth: false },
    children: [
      {
        path: "",
        name: "loginView",
        component: () => import("../views/LoginView.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPasswordView",
        component: () => import("../views/ForgotPasswordView.vue"),
      },
      {
        path: "reset-password",
        name: "ResetPasswordView",
        component: () => import("../views/ResetPasswordView.vue"),
      },
      {
        path: "email-verification",
        name: "EmailVerifyView",
        component: () => import("../views/EmailVerifyView.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "",
      },
    ],
  },
  {
    path: "/dashboard",
    name: "mainLayout",
    component: MainLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "DashboardView",
        component: () => import("../views/DashboardView.vue"),
      },

      {
        path: "companies",
        name: "UsersView",
        component: () => import("../views/UsersView.vue"),
      },

      {
        path: "company-details",
        name: "UserDetailsView",
        component: () => import("../views/UserDetailsView.vue"),
      },

      {
        path: "people",
        name: "StaffView",
        component: () => import("../views/StaffView.vue"),
      },

      {
        path: "people-details",
        name: "StaffDetailsView",
        component: () => import("../views/StaffDetailsView.vue"),
      },

      {
        path: "merchants",
        name: "GiftMerchantView",
        component: () => import("../views/GiftMerchantView.vue"),
      },

      {
        path: "gift-categories",
        name: "GiftCategoryView",
        component: () => import("../views/GiftCategoryView.vue"),
      },

      {
        path: "gift-sub-categories",
        name: "GiftSubCategoryView",
        component: () => import("../views/GiftSubCategoryView.vue"),
      },

      {
        path: "gift-box-details",
        name: "GiftBoxDetailsView",
        component: () => import("../views/GiftBoxDetailsView.vue"),
      },

      {
        path: "gift-boxes",
        name: "GiftView",
        component: () => import("../views/GiftView.vue"),
      },

      {
        path: "perk-cards",
        name: "PerkCardView",
        component: () => import("../views/PerkCardView.vue"),
      },

      {
        path: "perk-card-details",
        name: "PerkCardDetailsView",
        component: () => import("../views/PerkCardDetailsView.vue"),
      },

      {
        path: "staff-bank-transfer",
        name: "StaffBankTransferView",
        component: () => import("../views/StaffBankTransferView.vue"),
      },

      {
        path: "perk-card-conversion",
        name: "PerkCardConversionView",
        component: () => import("../views/PerkCardConversionView.vue"),
      },

      {
        path: "staff-bank-transfer-details",
        name: "StaffBankTransferDetailsView",
        component: () => import("../views/StaffBankTransferDetailsView.vue"),
      },

      {
        path: "automated-messages",
        name: "MessageView",
        component: () => import("../views/MessageView.vue"),
      },

      {
        path: "automated-message-details",
        name: "MessageDetailsView",
        component: () => import("../views/MessageDetailsView.vue"),
      },
      {
        path: "company-order",
        name: "OrderHistoryView",
        component: () => import("../views/OrderHistoryView.vue"),
      },
      {
        path: "company-order-details",
        name: "OrderDetailsView",
        component: () => import("../views/OrderDetailsView.vue"),
      },

      {
        path: "people-orders",
        name: "PeopleOrderView",
        component: () => import("../views/PeopleOrderView.vue"),
      },

      {
        path: "people-order-details",
        name: "PeopleOrderDetailsView",
        component: () => import("../views/PeopleOrderDetailsView.vue"),
      },

      {
        path: "product-request",
        name: "ProductRequestView",
        component: () => import("../views/ProductRequestView.vue"),
      },

      {
        path: "transactions",
        name: "TransactionsView",
        component: () => import("../views/TransactionsView.vue"),
      },
      {
        path: "transaction-details",
        name: "TransactionDetailsView",
        component: () => import("../views/TransactionDetailsView.vue"),
      },

      {
        path: "transaction-invoice/perk-cards/:id",
        name: "PerkCardInvoiceView",
        component: () => import("../views/PerkCardInvoiceView.vue"),
      },
      {
        path: "transaction-invoice/gift-boxes/:id",
        name: "GiftBoxInvoiceView",
        component: () => import("../views/GiftBoxInvoiceView.vue"),
      },

      {
        path: "admin",
        name: "RolesView",
        component: () => import("../views/RoleView.vue"),
      },
      {
        path: "admin-details",
        name: "RoleDetailsView",
        component: () => import("../views/RoleDetailsView.vue"),
      },
      {
        path: "setting",
        name: "SettingView",
        component: () => import("../views/SettingView.vue"),
      },
      {
        path: "chivas-regal-promo",
        name: "ChivasRegalPromo",
        component: () => import("../views/ChivasRegalPromo.vue"),
      },
      {
        path: "chivas-regal-promo-details",
        name: "ChivasRegalPromoDetails",
        component: () => import("../views/ChivasRegalPromoDetails.vue"),
      },
      {
        path: "/:pathMatch(.*)*",
        redirect: "/dashboard",
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    next({
      path: "/",
    });
    return;
  }
  next();
});

export default router;
