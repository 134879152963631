import { createApp } from "vue";
import { createPinia } from "pinia";
import { markRaw } from "vue";

import App from "./App.vue";
import "@/assets/app.css";
import filters from "@/helpers/filters";
import router from "./router";
import { Router } from "vue-router";

const app = createApp(App);

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: Router;
  }
}
const pinia = createPinia();

pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(pinia);
app.use(router);
app.config.globalProperties.$filters = filters;
app.mount("#app");
