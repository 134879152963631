
import { defineComponent } from "vue";

export default defineComponent({
  name: "AlertBar",
  props: {
  alertType: {
     type: String,
    required: true,
      default: "",
    },
    alertMessage: {
      type: String,
      required: true,
      default: "",
    },
  },
  emits: ['closeAlert'],
});
