import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AlertBar = _resolveComponent("AlertBar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_router_view),
    _createVNode(_Transition, { name: "slide-fade" }, {
      default: _withCtx(() => [
        (_ctx.toast.status)
          ? (_openBlock(), _createBlock(_component_AlertBar, {
              key: 0,
              alertType: _ctx.toast.type,
              alertMessage: _ctx.toast.message,
              onCloseAlert: _ctx.closeAlert
            }, null, 8, ["alertType", "alertMessage", "onCloseAlert"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}