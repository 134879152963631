import moment from "moment";

export const filters = {
  // capitalize(value) {
  //   if (!value) return "";
  //   value = value.toString();
  //   const arr = value.split(" ");
  //   const capitalizedArray = [];
  //   arr.forEach((word) => {
  //     const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  //     capitalized_array.push(capitalized);
  //   });
  //   return capitalizedArray.join(" ");
  // },
  // lowercase(value) {
  //   if (!value) return "";
  //   value = value.toString();
  //   const strg = value.toLowerCase();
  //   return strg.charAt(0).toUpperCase() + strg.slice(1);
  // },
  toThousandFormat(value: string) {
    const newValue = parseFloat(value);
    if (newValue)
      return newValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    else return "0.00";
  },
  // formatInputCurrency(value: string | number) {
  //   if (value && value != 0) {
  //     value = value.replace(/,/g, "");
  //     if (+value) {
  //       if (value.includes(".")) {
  //         if (value.split(".")[1].length >= 0) {
  //           const valueBeforeComma = value.split(".")[0];
  //           const valueAfterComma = value.split(".")[1];
  //           let numBeforeComma;
  //           if (valueAfterComma.length <= 2) {
  //             const numBeforeComma = value
  //               .split(".")[0]
  //               .toString()
  //               .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //             return `${numBeforeComma}.${valueAfterComma}`;
  //           } else {
  //             numBeforeComma = `${valueBeforeComma}${
  //               valueAfterComma.split("")[0]
  //             }`;
  //             const numAfterComma = `${valueAfterComma.split("")[1]}${
  //               valueAfterComma.split("")[2]
  //             }`;
  //             const numBeforeCommaFormatted = numBeforeComma
  //               .toString()
  //               .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //             return `${numBeforeCommaFormatted}.${numAfterComma}`;
  //           }
  //         }
  //       } else {
  //         return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  //       }
  //     }
  //   }
  // },
  toFriendlyDate(value: Date) {
    if (value) {
      return moment(value).format("DD MMMM, YYYY");
    }
  },
  //   toTimeDate(value) {
  //     if (value) {
  //       return moment(value).format("h:mm:ss A DD MMMM, YYYY");
  //     }
  //   },
};

export default filters;
