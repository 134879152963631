
import { defineComponent, watch, ref, onUnmounted, onMounted } from "vue";

import { storeToRefs } from "pinia";
import { useHomeStore } from "@/stores";
import { useAuthStore } from "@/stores/auth";

import AlertBar from "@/components/Alert.vue";

export default defineComponent({
  components: {
    AlertBar,
  },
  setup() {
    const homeStore = useHomeStore();
    const authStore = useAuthStore();
    const { toast } = storeToRefs(homeStore);
    const { closeAlert } = homeStore;
    const { autoLogin } = authStore;
    const alertTimer = ref();

    watch(toast, (newToast) => {
      clearTimeout(alertTimer.value);
      if (newToast.status) {
        alertTimer.value = setTimeout(() => {
          closeAlert();
        }, 10000);
      }
    });

    onUnmounted(() => clearTimeout(alertTimer.value));

    onMounted(() => {
      autoLogin();
    });

    return {
      toast,
      closeAlert,
    };
  },
});
