import { defineStore } from "pinia";
import { useHomeStore } from "@/stores";
import axios from "@/Axios";

type User = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  companyAddress: string;
  companyWebsite: string;
  role: string;
  status: string;
  emailVerified: boolean;
  token: string;
  type: string;
  expiresIn: number;
  createdAt: string;
  updatedAt: string;
};

export const useAuthStore = defineStore("auth", {
  state: () => {
    return {
      bottonLoading: false,
      login: {
        email: "",
        password: "",
      },
      password: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      register: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        companyAddress: "",
        companyWebsite: "",
        password: "",
      },
      userData: {
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        companyAddress: "",
        companyWebsite: "",
        role: "",
        status: "",
        emailVerified: false,
        token: "",
        type: "",
        expiresIn: 0,
        createdAt: "",
        updatedAt: "",
      } as User,
      expireTimer: null as null | ReturnType<typeof setTimeout>,
      logoutTimer: null as null | ReturnType<typeof setTimeout>,
      errorMessage: "",
      userEmail: "",
    };
  },
  getters: {
    isAuthenticated(state) {
      return !!state.userData.token;
    },
    setToken(state) {
      return state.userData.token;
    },
  },
  actions: {
    async forgotUserPassword(payload: { email: string }) {
      try {
        this.bottonLoading = true;
        const response = await axios.post("forgotPassword", payload);
        this.handleAlert(true, "success", response.data.message);
        this.userEmail = "";
        this.bottonLoading = false;
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async resetUserPassword(payload: { token: string; new_password: string }) {
      try {
        this.bottonLoading = true;
        const response = await axios.post("resetPassword", payload);
        this.handleAlert(true, "success", response.data.message);
        this.bottonLoading = false;
        this.router.push("/");
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async verifyUserEmail(payload: { token: string }) {
      try {
        this.bottonLoading = true;
        const response = await axios.post("verifyEmail", payload);
        this.handleAlert(true, "success", response.data.message);
        this.bottonLoading = false;
        this.router.push("/");
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async resendPasswordToken(id: string) {
      try {
        this.bottonLoading = true;
        const response = await axios.get(`resendToken/${id}`);
        this.handleAlert(true, "success", response.data.message);
        this.bottonLoading = false;
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async resendEmailToken(id: string) {
      try {
        this.bottonLoading = true;
        const response = await axios.get(`resendToken/${id}`);
        this.handleAlert(true, "success", response.data.message);
        this.bottonLoading = false;
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async loginUser(email: string, password: string) {
      try {
        this.bottonLoading = true;

        const payload = {
          email,
          password,
        };

        const response = await axios.post("loginAdmin", payload);

        this.userData = {
          userId: response.data.data.user.id,
          firstName: response.data.data.user.first_name,
          lastName: response.data.data.user.last_name,
          email: response.data.data.user.email,
          phone: response.data.data.user.phone,
          companyName: response.data.data.user.company_name,
          companyAddress: response.data.data.user.company_address,
          companyWebsite: response.data.data.user.company_website,
          role: response.data.data.user.role,
          status: response.data.data.user.status,
          emailVerified: response.data.data.user.email_verified,
          token: response.data.data.token,
          type: response.data.data.type,
          expiresIn: response.data.data.expires_in,
          createdAt: response.data.data.user.created_at,
          updatedAt: response.data.data.user.updated_at,
        };

        let expiresIn = Number(response.data.data.expires_in) * 1000;
        expiresIn = expiresIn - 60000;
        const expirationDate = new Date().getTime() + expiresIn;

        this.expireTimer = setTimeout(() => {
          this.getRefreshToken();
        }, expiresIn);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;

        localStorage.setItem("userData", JSON.stringify(this.userData));
        localStorage.setItem("expirationDate", String(expirationDate));

        this.router.push("/dashboard");
      } catch (error: any) {
        console.log(error);
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    async getRefreshToken() {
      try {
        this.bottonLoading = true;

        const response = await axios.get("refresh");

        if (this.logoutTimer) {
          clearTimeout(this.logoutTimer);
        }

        this.userData = {
          userId: response.data.data.user.id,
          firstName: response.data.data.user.first_name,
          lastName: response.data.data.user.last_name,
          email: response.data.data.user.email,
          phone: response.data.data.user.phone,
          companyName: response.data.data.user.company_name,
          companyAddress: response.data.data.user.company_address,
          companyWebsite: response.data.data.user.company_website,
          role: response.data.data.user.role,
          status: response.data.data.user.status,
          emailVerified: response.data.data.user.email_verified,
          token: response.data.data.token,
          type: response.data.data.type,
          expiresIn: response.data.data.expires_in,
          createdAt: response.data.data.user.created_at,
          updatedAt: response.data.data.user.updated_at,
        };

        let expiresIn = Number(response.data.data.expires_in) * 1000;
        expiresIn = expiresIn - 60000;
        const expirationDate = new Date().getTime() + expiresIn;

        this.expireTimer = setTimeout(() => {
          this.getRefreshToken();
        }, expiresIn);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.data.token}`;

        localStorage.setItem("userData", JSON.stringify(this.userData));
        localStorage.setItem("expirationDate", String(expirationDate));
      } catch (error: any) {
        this.bottonLoading = false;
        const errMessage =
          error?.response?.data?.message || "Something went wrong";
        this.handleAlert(true, "error", errMessage);
      }
    },

    autoLogin() {
      const userData = localStorage.getItem("userData");
      const expirationDate = localStorage.getItem("expirationDate");
      if (userData) {
        const user = JSON.parse(userData);

        if (expirationDate) {
          const expiresIn = Number(expirationDate) - new Date().getTime();

          if (expiresIn < 0) {
            return;
          }

          this.expireTimer = setTimeout(() => {
            this.getRefreshToken();
          }, expiresIn);
        }

        this.userData = {
          userId: user.userId,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          companyName: user.companyName,
          companyAddress: user.companyAddress,
          companyWebsite: user.companyWebsite,
          role: user.role,
          status: user.status,
          emailVerified: user.emailVerified,
          token: user.token,
          type: user.type,
          expiresIn: user.expiresIn,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
        };

        axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
      }
    },

    async logout() {
      this.userData = {
        userId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        companyName: "",
        companyAddress: "",
        companyWebsite: "",
        role: "",
        status: "",
        emailVerified: false,
        token: "",
        type: "",
        expiresIn: 0,
        createdAt: "",
        updatedAt: "",
      };

      if (this.expireTimer) {
        clearTimeout(this.expireTimer);
      }

      localStorage.removeItem("userData");
      localStorage.removeItem("expirationDate");
      await axios.post("logout");
      this.router.push("/");
    },

    async handleAlert(status: boolean, type: string, message: string) {
      const homeStore = await useHomeStore();
      if (type === "error") {
        homeStore.toast = {
          status: status,
          type: type,
          message: message || "something went wrong.",
        };
        this.errorMessage = message || "something went wrong.";
      } else if (type === "success") {
        homeStore.toast = {
          status: status,
          type: type,
          message: message || "Successful.",
        };
        this.errorMessage = message || "Successful.";
      }
    },
  },
});
